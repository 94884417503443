<template>
  <h1>Referrals</h1>

  <h4>Refer to TAMIL Crypto and earn 25% in TAMIL!</h4>

  <br /><br />
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
      <h3>Refer friends</h3>
      <p>
        Share your referral link with friends via private message or as a post
        in your social media, video or article. We will reward you for every
        investment made from your referral. Earn 25% of TAMIL invested by every
        person you refer
      </p>

      <router-link class="btn btn-warning" to="/signup">
        <i class="fa fa-user"></i> Register Now
      </router-link>
    </div>
    <!-- div container end -->

    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <img
        src="@/assets/images/tamiltoken-friend-referral.jpg"
        alt="tamiltoken-friend-referral"
        style="width: 100%"
      />
      <p>
        You are able to track your referral earnings, CTR, and traffic in real
        time via the TAMIL Crypto Web App.
      </p>
    </div>
  </div>
  <!-- row -->

  <!-- row -->
  <div class="row mt-5 mb-3">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <img
        src="@/assets/images/tamiltoken-social-media-influencer.jpg"
        alt="tamiltoken-social-media-influencer"
        style="width: 100%"
      />
      <p>
        Are you running a YouTube Channel, Twitter account, or social media
        group?
      </p>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
      <h3>Professional Affiliate or Influencer?</h3>
      <p>
        Become our influencer and promote TAMIL Crypto Launchpad and get TAMIL.
        Get access to live tracking features. Promote TAMIL Crypto via:
      </p>

      <ul class="no-icon">
        <li>YouTube video</li>
        <li>Telegram post</li>
        <li>Social Media post</li>
        <li>Other marketing methods</li>
      </ul>

      <router-link to="/affiliate-register" class="btn btn-warning">
        <i class="fa fa-users"></i> Register Now
      </router-link>
    </div>
  </div>
  <!-- row -->
</template>

<script>
export default {};
</script>

<style scoped>
.no-icon li {
  padding: 5px;
  list-style-type: none;
  border-bottom: 1px solid rgb(117, 110, 110);
}
</style>